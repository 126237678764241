




























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import PortalData from 'eontyre-booking-portal/src/utils/PortalData'
import SelectedService from 'eontyre-booking-portal/src/models/SelectedService'
import CustomerInfo from 'eontyre-booking-portal/src/models/CustomerInfo'
import Booking from 'eontyre-booking-portal/src/models/Booking'
import Reference from 'eontyre-booking-portal/src/models/Reference'
import TyreHotel from 'eontyre-booking-portal/src/models/TyreHotel'
import Calendar from 'eontyre-booking-portal/src/components/Calendar.vue'
import ServiceSelector from 'eontyre-booking-portal/src/components/ServiceSelector.vue'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {
    Calendar,
    ServiceSelector,
  },
})
export default class New extends Vue {
  private portalData: PortalData = null
  private service: SelectedService = null
  private customer: CustomerInfo = null
  private booking: Booking = null
  private tyreHotel: TyreHotel = null
  private reference: Reference = null
  private loading = true
  private modifyExistingBooking: Booking = null
  private selectedServiceKey = ''
  private isReady = false

  private calendars = []
  private selectedCalendar = null
  private hasLoaded = false

  public async created(): Promise<void> {
    this.service = new SelectedService()
    this.customer = new CustomerInfo()
    this.tyreHotel = new TyreHotel()
    this.reference = new Reference()
    this.booking = null

    this.reference.type = this.$route.params.referenceType
    this.reference.id = parseInt(this.$route.params.referenceId) || null
    this.reference.season = parseInt(this.$route.params.referenceParam) || null

    this.loadExistingBooking(() => {
      this.loadReference(() => {
        this.loadPortal(() => {
          this.isReady = true
        })
      })
    })

    await this.$axios.get(this.calendarUrl).then((response) => {
      this.calendars = response.data.data
      if (this.calendars && this.calendars.length > 1) {
        const defaultCalendar = this.calendars.find((calendar) => calendar.isDefault)
        if (defaultCalendar) {
          this.selectedCalendar = defaultCalendar.id
        }
      }
    })

    this.$nextTick(() => {
      this.hasLoaded = true
    })
  }

  private loadReference(callback) {
    if (this.reference.type && this.reference.id) {
      this.$axios
        .get(
          '/v4/site/calendars/any/portals/admin/lookup-hotel?referenceType=' +
            encodeURIComponent(this.reference.type) +
            '&referenceId=' +
            encodeURIComponent(this.reference.id),
        )
        .then((response) => {
          this.tyreHotel.bookingServiceId = response.data.data.tyreHotelBookingServiceId
          this.tyreHotel.productDescription = response.data.data.tyreHotelProductDescripion
          callback()
        })
        .catch((err) => {
          console.error('Error looking up hotel:', err)
          callback()
        })
    } else {
      callback()
    }
  }

  private loadExistingBooking(callback) {
    if (this.isModify) {
      const url = '/v4/site/calendars/any/bookings/' + this.$route.params.id
      this.$axios
        .get(url)
        .then((response) => {
          this.modifyExistingBooking = new Booking()
          this.modifyExistingBooking.populate(response.data.data)
          const addonServiceIds = []
          for (let i = 0; i < response.data.data.work.length; i++) {
            const w = response.data.data.work[i]
            if (w.isPrimary) {
              this.onServiceChange({ key: 'serviceId', value: w.serviceId })
            } else {
              addonServiceIds.push(w.serviceId)
            }
          }
          this.onServiceChange({ key: 'addonServiceIds', value: addonServiceIds })
          this.reference.type = 'order'
          this.reference.id = response.data.data.orderId
          callback()
        })
        .catch((err) => {
          console.error('Error loading booking:', err)
          callback()
        })
    } else {
      callback()
    }
  }

  private loadPortal(callback) {
    this.portalData = new PortalData(this.$axios)
    this.portalData.loadAdminPortal((err) => {
      if (err) {
        console.error('Error fetching portal:', err, err.response?.data)
      } else {
        if (this.portalData.places.length === 1) {
          this.service.placeId = this.portalData.places[0].id
        }
        document.title = this.portalData.portal.name
        // vxm.locale.setLocale(this.portalData.locale)
      }
      this.loading = false
      callback()
    })
  }

  private get isModify(): boolean {
    return !!this.$route.params?.id
  }

  @Watch('booking')
  private onBooked() {
    const url = this.getBookingRedirectUrl(this.booking)
    if (!url) {
      return
    }
    this.$router.push(url)
  }

  private getBookingRedirectUrl(booking: Booking): string {
    const back = (this.$route.query?.back as string) || ''
    if (back !== '') {
      return back
    }
    if (booking.tyreHotelId) {
      return appendSitePrefix('/' + booking.tyreHotelId + '/storage/item')
    } else if (booking.orderId) {
      return appendSitePrefix('/' + booking.orderId + '/order2/main')
    } else {
      console.error('Could not find redirect url for booking:', booking)
    }
    return ''
  }

  private get timezone(): string {
    return vxm?.user?.profile?.tz || 'UTC'
  }

  private onServiceChange(evt) {
    for (const key in evt) {
      this.service[key] = evt[key]
    }
    this.selectedServiceKey = JSON.stringify(this.service)
  }

  private get debugVisible(): boolean {
    return vxm.user.isSuperUser
  }

  private openDebug() {
    const url = appendSitePrefix(
      '/bookings/debug-times?service=' +
        this.service.serviceId +
        '&place=' +
        this.service.placeId +
        '&portal=' +
        this.portalData.portal.id,
    )
    window.open(url)
  }

  @Watch('selectedCalendar')
  private async onCalendarSelectionChange() {
    if (!this.hasLoaded) return

    this.isReady = false
    await this.$axios.put(this.calendarUrl + '/' + this.selectedCalendar + '/set-default').finally(() => {
      this.loadPortal(() => {
        this.isReady = true
      })
    })
  }

  private get calendarUrl() {
    return '/v4/site/calendars'
  }
}
